$primary: #f08073;
$info: #34556e;
$link: $primary;
$link-visited: $primary;
$red: #FF4564;
$red-invert: findColorInvert($red);
$primary-invert: #fff;
$link-hover: $primary;
$link-hover-border: $primary;
$link-focus: $primary;
$link-focus-border: $primary;
$link-active: $primary;
$link-active-border: $primary;
$dark: $info;
$body-color: $dark;
$content-heading-color: $info;
$content-table-cell-heading-color: $info;
$text: $dark;
$text-strong: $dark;
$strong-color: $dark;
$pre: $dark;
