// Variables

$border: #DADFE9 !default;
$border-hover: #E6EAF1 !default;
$border-light: #F2F5FA !default;
$border-light-hover: #E6EAF1 !default;
$radius: 5px !default;
$radius-small: 0.125rem !default;
$radius-large: 0.375rem !default;
$radius-2x-large: 0.5rem !default;
$text: #0A2463 !default;
$text-strong: #0A2463 !default;

$primary: #FF4564 !default;
$success: #297373 !default;
$info: #45C1FF !default;
$warning: #E9D758 !default;
$danger: #FF4564 !default;
$light: #F2F5FA !default;
$dark: #0A2463 !default;
$orange: #E4572E !default;
$yellow: #E9D758 !default;
$green: #297373 !default;
$blue: #45C1FF !default;
$red: #FF4564 !default;

$grey-darker: #657084 !default;
$grey-dark: #838EA4 !default;
$grey-light: #ACB4C4 !default;
$grey-lighter: #C8CEDA !default;
$white-ter: #F2F5FA !default;

$link: #FF4564 !default;
$link-visited: #FF4564 !default;
$link-hover: #FF1F44 !default;
$link-hover-border: #FF1F44 !default;
$link-focus: #FF1F44 !default;
$link-focus-border: #FF1F44 !default;
$link-active: #FF1F44 !default;
$link-active-border: #FF1F44 !default;

$container-max-width: 1152px !default;
$body-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

$title-weight: 600 !default;
$size-1: 4rem !default;
$size-2: 3rem !default;
$size-3: 2.25rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1.125rem !default;
$size-7: 1rem !default;

$tag-color: #fff !default;
$tag-radius: 5px !default;
$tag-delete-margin: 1px !default;

$size-normal: 0.875rem !default;

$button-padding-vertical: 1.5rem !default;
$button-padding-horizontal: 1.5rem !default;

$box-radius: 5px !default;
$box-shadow: none !default;
$box-border-width: 1px !default;
$box-border-color: #DADFE9 !default;
$box-border-style: solid !default;

$card-radius: 5px !default;
$card-shadow: 0px 1px 1px #C8CEDA !default;
$panel-radius: 5px !default;
$panel-shadow: none !default;

$navbar-item-font-size: 0.875rem !default;
$navbar-item-font-weight: 500 !default;
$navbar-item-color: #0A2463 !default;
$navbar-item-hover-color: #0A2463 !default;
$navbar-item-hover-background-color: transparent !default;
$navbar-item-padding-x: 1.5rem !default;
$navbar-item-padding-y: .5rem !default;

$notification-color: null !default;
$notification-font-size: null !default;

$notification-border-width: 0 !default;
$notification-border-top-width: $notification-border-width !default;
$notification-border-right-width: $notification-border-width !default;
$notification-border-bottom-width: $notification-border-width !default;
$notification-border-left-width: $notification-border-width !default;

$notification-radius: 5px !default;
$notification-border-top-right-radius: $notification-radius !default;
$notification-border-top-left-radius: $notification-radius !default;
$notification-border-bottom-right-radius: $notification-radius !default;
$notification-border-bottom-left-radius: $notification-radius !default;
